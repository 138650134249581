<template>
    <div class="card-tail">
        <p class="border-b pb-2 mb-4">
            Notera
        </p>
        <p>
            {{ noteData }}
        </p>
    </div>
</template>
<script>
export default {
    props: {
        noteData: {
            type: String,
            default: "",
        },
    },
};
</script>
